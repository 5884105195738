<template>
  <div class="orderRefundSure">
    <el-card class="box-card father-card"
             shadow="never">
      <el-row class="btns">
        <el-form ref="form"
                 :model="form"
                 inline>
          <el-form-item label="订单编号">
            <el-input v-model.trim="form.orderNo"
                      placeholder="请输入订单编号"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="电话号码">
            <el-input v-model.trim="form.memberMobile"
                      placeholder="请输入手机号码"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="支付流水号">
            <el-input v-model.trim="form.payno"
                      placeholder="请输入支付流水号"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="订单开始结束时间">
                <WTimer ref="WTimer" v-model="form" :startEnd="{start:'starOrderDate',end:'endOrderDate'}" />
           </el-form-item>
          <!-- <el-form-item label="订单开始时间"
                        prop="disinfectTime">
            <el-date-picker v-model="form.starOrderDate"
                            type="datetime"
                            placeholder="开始时间"
                            label-width="10px"
                            clearable
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item label="到"
                        prop="disinfectTime">
            <el-date-picker v-model="form.endOrderDate"
                            type="datetime"
                            placeholder="结束时间"
                            clearable
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item> -->
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="success"
                       icon="el-icon-search"
                       @click="search()">搜索</el-button>
            <el-button type="primary"
                       icon="el-icon-delete"
                       @click="reset()">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="tab">
        <!-- 表格区域 -->
        <el-table ref="multipleTable"
                  :data="List"
                  tooltip-effect="dark"
                  style="width: 100%"
                  highlight-current-row
                  stripe
                  border
                  :header-cell-style="rowClass">
          <el-table-column prop="auditId"
                           label="审计ID"
                           align="center"></el-table-column>
          <el-table-column prop="orderNo"
                           label="订单编号"
                           align="center"></el-table-column>
          <el-table-column prop="payNo"
                           label="支付流水号"
                           align="center"></el-table-column>
          <el-table-column prop="payChannel"
                           label="支付渠道"
                           align="center">
            <template slot-scope="scope">
              <div effect="dark"
                   type="success"
                   v-if="scope.row.payChannel === '1'">
                {{ '微信' }}
              </div>
              <div effect="dark"
                   v-else-if="scope.row.payChannel === '2'">
                {{ '支付宝' }}
              </div>
              <div effect="dark"
                   type="warning"
                   v-else-if="scope.row.payChannel === '3'">
                {{ '账户余额' }}
              </div>
              <div type="danger"
                   effect="dark"
                   v-else-if="scope.row.payChannel === '4'">
                {{ '二维码' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="payType"
                           label="支付类型"
                           align="center">
            <template slot-scope="scope">
              <span effect="dark"
                    type="warning"
                    v-if="scope.row.payType === '0'">{{ '预付' }}</span>
              <span type="success"
                    effect="dark"
                    v-else-if="scope.row.payType === '1'">{{
                                '完成支付'
                            }}</span>
              <span type="success"
                    effect="dark"
                    v-else-if="scope.row.payType === '2'">{{"补充收款"}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="payFee"
                           label="支付费用"
                           align="center"></el-table-column>
          <el-table-column prop="refundFee"
                           label="退款费用"
                           align="center"></el-table-column>
          <!-- <el-table-column prop="payTime" label="支付时间" align="center"></el-table-column> -->
          <el-table-column prop="memeberName"
                           label="申请退款人"
                           align="center"></el-table-column>
          <el-table-column prop="memeberPhone"
                           label="退款人电话"
                           align="center"></el-table-column>
          <el-table-column prop="refundReturn"
                           label="退款信息"
                           align="center"></el-table-column>
          <el-table-column prop="remark"
                           label="备注"
                           align="center"></el-table-column>
          <el-table-column prop="status"
                           label="状态"
                           align="center">
            <template slot-scope="scope">
              <span effect="dark"
                    type="warning"
                    v-if="scope.row.status === '0'">{{ '待审核' }}</span>
              <span effect="dark"
                    type="success"
                    v-else-if="scope.row.status === '1'">{{ '审核通过' }}
              </span>
              <span effect="dark"
                    type="danger"
                    v-else-if="scope.row.status === '2'">{{ '审核未通过' }}
              </span>
              <span effect="dark"
                    type="info"
                    v-else-if="scope.row.status === '3'">{{ '已退款' }}</span>
              <span type="info"
                    effect="dark"
                    v-else>{{
                                '退款失败'
                            }}</span>
            </template>
          </el-table-column>
          <el-table-column label="详情"
                           align="center"
                           min-width="150px">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="look(scope.row)"
                         type="success"
                         v-if="scope.row.status === '0'">审核成功</el-button>
              <el-button size="mini"
                         @click="refunde(scope.row)"
                         type="success"
                         v-if="scope.row.status === '0'">审核失败</el-button>
              <el-button size="mini"
                         @click="refund(scope.row)"
                         type="warning"
                         v-if="scope.row.status === '1'&&scope.row.payChannel != 4">确认退款</el-button>
              <el-popconfirm confirm-button-text='确定'
                             cancel-button-text='取消'
                             icon="el-icon-info"
                             icon-color="red"
                             title="确认退款？"
                             v-if="scope.row.status === '1'&&scope.row.payChannel == 4"
                             @confirm="refund(scope.row)">
                <el-button  type="warning" size="mini"
                           slot="reference">确认退款</el-button>
              </el-popconfirm>

              <!-- <el-popconfirm title="确认退款？">
                <el-button size="mini"
                           @click="refund(scope.row)"
                           type="warning">确认退款</el-button>
              </el-popconfirm> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handlePageNumChange"
                       :current-page="paging.pageNo"
                       :page-sizes="[10, 15, 20, 50]"
                       :page-size="paging.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total"></el-pagination>
        <!-- 订单详情弹出框 -->
        <el-dialog :visible.sync="orderDialogFormVisible"
                   class="order-dialog"
                   @close="orderClo">
          <span slot="title"
                class="dialog-Title">订单退款审核意见</span>
          <el-form :model="orderForm"
                   inline
                   ref="orderForm"
                   :rules="orderFormRules">
            <!-- 第一行 -->
            <el-row>
              <el-form-item label="审核理由"
                            prop="auditReason">
                <el-input v-model="orderForm.auditReason"
                          autocomplete="off"></el-input>
              </el-form-item>
            </el-row>
          </el-form>
          <div slot="footer"
               class="dialog-footer">
            <el-button type="primary"
                       @click="refundErr">确 认</el-button>
          </div>
        </el-dialog>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
import store from '@/store/index.js'
import WTimer from '@/components/dateCpn/dataPiceker'

export default {
  name: 'orderRefund',
  components: {
    WTimer
  },
  data () {
    return {
      flag: true,
      List: [], // 表格数据源
      form: {
        orderNo: '',
        memberMobile: '',
        payno: '',
        starOrderDate: '',
        endOrderDate: ''
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderForm: {},
      orderFormRules: {
        auditReason: [
          {
            required: true,
            message: '请输入审核意见',
            trigger: 'blur'
          }
        ]
      }, // 编辑资料表单验证规则
      isdisable: true
    }
  },
  created () {
    this.getOrderList()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getOrderList()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getOrderList()
    },
    // 获取订单退款
    async getOrderList () {
      const { data: res } = await this.$http.post(
        '/userManageServer/wxMiniPay/wxApplyPayRefundList',
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          headers: {
            AUTH_TYPE: 'orderRefund',
            CORP_ID: store.state.order_company || sessionStorage.getItem('order_company')
          }
        }
      )
      console.log(res)
      if (res.statusCode !== '200') {
        return this.$message.error('获取订单列表失败')
      }
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 重置
    reset () {
      this.form.orderNo = ''
      this.form.memberMobile = ''
      this.form.payno = ''
      this.form.starOrderDate = ''
      this.form.endOrderDate = ''
      this.$refs.WTimer.resetFields()
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search()
    },
    // 点击搜索按钮触发
    async search () {
      // 调接口，进行查询
      this.paging.PageNo = 1
      const { data: res } = await this.$http.post(
        '/userManageServer/wxMiniPay/wxApplyPayRefundList',
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          orderNo: this.form.orderNo,
          payNo: this.form.payno,
          memberMobile: this.form.memberMobile,
          starOrderDate: this.form.starOrderDate,
          endOrderDate: this.form.endOrderDate,
          headers: {
            AUTH_TYPE: 'orderRefund',
            CORP_ID: store.state.order_company || sessionStorage.getItem('order_company')
          }
        }
      )
      console.log(this.form.memberMobile)
      // 判断请求是否成功
      if (res.statusCode !== '200') {
        return this.$message.error('查询失败')
      }
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
      // 判断有输入账号
      //   if (this.form.orderNo === '' && this.form.memberMobile === '' && this.form.payno === '') {
      //     this.getOrderList()
      //   } else {
      // // /userManageServer/wxMiniPay/wxCanApplyPayRefund

      //   }
    },
    // 点击审核成功按钮触发
    async look (v) {
      this.orderForm = _.cloneDeep(v)
      this.orderForm.status = '1'
      this.orderForm.order = this.orderForm.orderNo
      this.orderForm.auditId = sessionStorage.getItem('userId')
      const { data: res } = await this.$http.post(
        '/userManageServer/wxMiniPay/updateWxRefundStatus',
        JSON.parse(JSON.stringify(this.orderForm))
      )
      console.log(res)
      if (res.statusCode !== '200') {
        return this.$message.error('审核失败')
      }
      this.$message.success('审核通过')
      this.getOrderList()
      // this.orderForm = v
      // this.orderDialogFormVisible = true
    },
    // 点击退款触发
    async refund (v) {
      console.log(v)
      if (v.payChannel == 4) {
        return this.$message.warning('请确认微信商户后台已退款')
      }
      if (!this.flag) {
        return this.$message.warning('正在审核，请耐心等待')
      }
      this.flag = false
      this.orderForm = _.cloneDeep(v)
      this.orderForm.money = Number(this.orderForm.refundFee)
      this.orderForm.order = this.orderForm.orderNo
      this.orderForm.auditId = sessionStorage.getItem('userId')
      console.log(JSON.parse(JSON.stringify(this.orderForm)))
      const { data: res } = await this.$http.post(
        '/userManageServer/wxMiniPay/wxPayRefund',
        JSON.parse(JSON.stringify(this.orderForm))
      )
      this.flag = true
      this.getOrderList()
    },
    // 点击审核失败按钮触发
    async refunde (v) {
      this.orderForm = _.cloneDeep(v)
      this.orderForm.order = this.orderForm.orderNo
      this.orderDialogFormVisible = true
      // v.auditReason = ""
      // const { data: res } = await this.$http.post('/userManageServer/wxMiniPay/updateWxRefundStatus', JSON.parse(JSON.stringify(v)))
      // console.log(res);
      // this.getOrderList()
    },
    // 点击审核失败确认按钮触发
    refundErr () {
      // 预校验
      this.$refs.orderForm.validate(async (valid) => {
        if (!valid) return
        this.orderForm.status = '2'
        this.orderForm.order = this.orderForm.orderNo
        this.orderForm.auditId = sessionStorage.getItem('userId')
        const { data: res } = await this.$http.post(
          '/userManageServer/wxMiniPay/updateWxRefundStatus',
          JSON.parse(JSON.stringify(this.orderForm))
        )
        if (res.statusCode !== '200') {
          return this.$message.error('审核失败')
        }
        this.getOrderList()
        this.orderDialogFormVisible = false
      })
    },
    orderClo () {
      this.orderForm = {}
    }
  }
}
</script>
<style lang="less" scoped>
.orderRefundSure {
  width: 100%;
  height: 100%;

  .father-card {
    .btns {
      margin-bottom: 10px;
    }

    .tab {
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }

      .order-dialog {
        .dialog-Title {
          color: #0097fe;
          font-size: 20px;
          font-weight: 700;
        }

        /deep/ .el-form {
          .subtitle {
            color: #0097fe;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 20px;
            padding-left: 6px;
            border-left: 5px solid #0097fe;
          }

          .el-row {
            display: flex;

            .el-form-item {
              flex: 1;
              display: flex;

              .el-form-item__content {
                flex: 1;

                .el-input {
                  width: 100%;

                  .el-input__inner {
                    width: 100%;
                  }
                }

                .el-select {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
